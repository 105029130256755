<up-spinner></up-spinner>
<div class="container-wrapper">
  <div id="header-wrapper" *ngIf="page !== PRODUCT_SELECTION && page !== ''">
    <go-header></go-header>
  </div>

  <main class="{{ containerClass }} content ">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-12">
        <up-bs-toast></up-bs-toast>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <div id="footer-wrapper" *ngIf="page !== PRODUCT_SELECTION && page !== ''">
    <up-footer></up-footer>
  </div>
</div>
<!-- Exit Modal -->
<ng-template #exitModal>
  <up-bs-modal [config]="bsModalConfig" (modalEvent)="handleModalEvent($event)">
    <div>
      <p class="m-b-8">
        {{ "LEARNER.SAVE_EXIT.EXIT_NOTE_2" | translate }}
      </p>
    </div>
  </up-bs-modal>
</ng-template>
