<div class="error-container">
  <div class="fs-4 error-container__icon-container d-inline-flex">
    <span class="mt-1"><mhicon-x-circle size="xxl"></mhicon-x-circle></span>
    <h1 class="d-flex align-items-center ms-4">
      {{ "GENERIC.ERROR.HEADING" | translate }}
    </h1>
  </div>
  <div class="fw-bold mb-4 h2">
    {{ errorMessageKey | translate }}
  </div>
  <div
    class="mb-2"
    *ngIf="genericError?.status !== 0 || genericError?.message !== ''">
    <button
      type="button"
      class="btn btn-outline-primary pe-3"
      (click)="detailedError.toggle()"
      [attr.aria-expanded]="!isCollapse">
      <span *ngIf="!isCollapse">
        <mhicon-chevron-down size="md"></mhicon-chevron-down>
      </span>
      <span *ngIf="isCollapse">
        <mhicon-chevron-right size="md"></mhicon-chevron-right>
      </span>
      {{ "GENERIC.ERROR.SHOW_ERROR" | translate }}
    </button>

    <div
      #detailedError="ngbCollapse"
      [(ngbCollapse)]="isCollapse"
      (shown)="renderDetailedError()">
      <div class="card rounded-0" *ngIf="errorSupportMessage">
        <div class="card-body">
          <div class="d-flex flex-row">
            <div>
              <div *ngIf="genericError?.status !== 0">
                <span class="fw-bold">
                  {{ "GENERIC.ERROR.ERROR_CODE" | translate }}
                </span>
                {{ genericError.status }}
              </div>
              <div *ngIf="genericError?.message !== ''">
                <span class="fw-bold">
                  {{ "GENERIC.ERROR.ERROR_MESSAGE" | translate }}
                </span>
                <pre><code [innerHTML]="genericError.message | json"></code></pre>
              </div>
              <div
                [innerHTML]="errorSupportMessage"
                *ngIf="
                  genericError?.status !== 0 || genericError?.message !== ''
                "></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-2">
    <button type="button" (click)="onExitClick()" class="btn btn-primary mt-2">
      {{ "GENERIC.ERROR.EXIT_BUTTON" | translate }}
    </button>
  </div>
</div>
